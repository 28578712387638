// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abusivismo-in-finanza-js": () => import("./../../../src/pages/abusivismo-in-finanza.js" /* webpackChunkName: "component---src-pages-abusivismo-in-finanza-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-criptovalute-e-monete-virtuali-js": () => import("./../../../src/pages/criptovalute-e-monete-virtuali.js" /* webpackChunkName: "component---src-pages-criptovalute-e-monete-virtuali-js" */),
  "component---src-pages-diversificazione-del-portafoglio-js": () => import("./../../../src/pages/diversificazione-del-portafoglio.js" /* webpackChunkName: "component---src-pages-diversificazione-del-portafoglio-js" */),
  "component---src-pages-finanza-ed-impulsivita-js": () => import("./../../../src/pages/finanza-ed-impulsivita.js" /* webpackChunkName: "component---src-pages-finanza-ed-impulsivita-js" */),
  "component---src-pages-i-rischi-della-speculazione-js": () => import("./../../../src/pages/i-rischi-della-speculazione.js" /* webpackChunkName: "component---src-pages-i-rischi-della-speculazione-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-consulenza-finanziaria-js": () => import("./../../../src/pages/la-consulenza-finanziaria.js" /* webpackChunkName: "component---src-pages-la-consulenza-finanziaria-js" */),
  "component---src-pages-pianificazione-finanziaria-js": () => import("./../../../src/pages/pianificazione-finanziaria.js" /* webpackChunkName: "component---src-pages-pianificazione-finanziaria-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rapporto-rischi-rendimenti-js": () => import("./../../../src/pages/rapporto-rischi-rendimenti.js" /* webpackChunkName: "component---src-pages-rapporto-rischi-rendimenti-js" */),
  "component---src-pages-trading-online-js": () => import("./../../../src/pages/trading-online.js" /* webpackChunkName: "component---src-pages-trading-online-js" */)
}

