import React, {createContext, useContext, useState} from 'react'

const CookiesContext = createContext({
  acceptedCookies: false,
  preferences: false,
  analytics: false,
  profiling: false,
  update: () => {},
})

export const CookiesProvider = props => {
  const [cookies, setCookies] = useState({
    acceptedCookies: false,
    preferences: false,
    analytics: false,
    profiling: false,
  })

  return (
    <CookiesContext.Provider value={{...cookies, update: setCookies}}>
      {props.children}
    </CookiesContext.Provider>
  )
}

export const useCookiesConsent = () => useContext(CookiesContext)
